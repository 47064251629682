import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProposalEventBlockDeleteRequestModel, ProposalEventBlockPutRequestModel } from '../models/proposal-details-event-info.model';
import { Observable } from 'rxjs';
import { GetProposalDetailsServiceUrlPath } from 'src/app/utils/constants';
import { GuestroomRequestBody, GuestRoomDeletRequestBody, GuestroomAddRequestBody } from '../models/proposal-details-guestroom-info-view-model';
import { GetProposalCommentsResponse, GetProposalDetailsResponse, PutProposalDetailsRequest } from '../models/rfp-proposal-details.model';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class RfpProposalDetailsService {

  private rfpProposalServiceUrl: string;
  private rfpProposalServiceBaseUrl: string;

  constructor(private http: HttpClient) {
    this.rfpProposalServiceBaseUrl = sessionStorage.getItem('RFPProposalServiceBaseUrl');
    this.rfpProposalServiceUrl = `${this.rfpProposalServiceBaseUrl}${GetProposalDetailsServiceUrlPath}`;
  }

  public getRfpProposalDetails(rfpId: string): Observable<GetProposalDetailsResponse> {
    let paramString = 'include=eventblocks&include=guestroomblocks&include=questions';
    return this.http.get<GetProposalDetailsResponse>(`${this.rfpProposalServiceUrl}/${rfpId}/proposal?${paramString}`);
  }

  public updateEventBlockProposalDetails(rfpId: string, eventBlockProposalInfo: ProposalEventBlockPutRequestModel) {
    return this.http.put<ProposalEventBlockPutRequestModel>(`${this.rfpProposalServiceBaseUrl}/v1/Rfps/${rfpId}/proposal/event-blocks`, eventBlockProposalInfo, { observe: 'response' });
  }

  public updateProposalGuestRoomBlock(rfpId: string, guestroomRequest: GuestroomRequestBody) {
    return this.http.put<GuestroomRequestBody>(`${this.rfpProposalServiceBaseUrl}/v1/Rfps/${rfpId}/proposal/guest-room-blocks`, guestroomRequest);
  }

  public addProposalGuestRoomBlock(rfpId: string, guestroomRequest: GuestroomAddRequestBody) {
    return this.http.put<GuestroomAddRequestBody>(`${this.rfpProposalServiceBaseUrl}/v1/Rfps/${rfpId}/proposal/guest-room-blocks`, guestroomRequest);
  }

  // Replicated above method with appropriate name for brevity
  public addProposalGuestRoomBlockList(rfpId: string, guestrooms: GuestroomAddRequestBody) {
    return this.addProposalGuestRoomBlock(rfpId, guestrooms);
  }

  public delteProposalGuestroomDays(rfpId: string, dayNumbers: GuestRoomDeletRequestBody) {
    return this.http.delete<GuestroomRequestBody>(`${this.rfpProposalServiceBaseUrl}/v1/Rfps/${rfpId}/proposal/guest-room-blocks`, { body: dayNumbers });
  }

  public getRfpProposalIncludeOnlyDetails(rfpId: string, paramString: string[]): Observable<GetProposalDetailsResponse> {
    let queryString: string = 'include=' + paramString.join('&');
    return this.http.get<GetProposalDetailsResponse>(`${this.rfpProposalServiceUrl}/${rfpId}/proposal?${queryString}`);
  }

  public updateRfpProposalDetails(rfpId: string, proposalDetails: PutProposalDetailsRequest) {
    return this.http.put<PutProposalDetailsRequest>(`${this.rfpProposalServiceUrl}/${rfpId}/proposal`, proposalDetails);
  }

  public deleteEventBlockProposalDetails(rfpId: string, eventBlockDeleteRequest: ProposalEventBlockDeleteRequestModel) {
    return this.http.delete<ProposalEventBlockDeleteRequestModel>(`${this.rfpProposalServiceBaseUrl}/v1/Rfps/${rfpId}/proposal/event-blocks`, { body: eventBlockDeleteRequest })
  }

  public sendProposal(rfpId: Guid) {
    return this.http.post(`${this.rfpProposalServiceBaseUrl}/v1/Rfps/${rfpId}/proposal/send`, null);
  }

  getProposalComments(locationId: string, isSubscription: boolean, category: string) {
    let queryString: string = 'locationId=' + locationId + '&subscription=' + isSubscription;
    return this.http.get<GetProposalCommentsResponse>(`${this.rfpProposalServiceUrl}/proposal-comments?${queryString}&category=${category}`).toPromise();
  }

}
