<app-spinner id="responder" *ngIf="isCommentsLoading || isLocationCommentsLoading"></app-spinner>

<div class="grid-media">
    <div class="modal-header">
        <h2 class="modal-title" i18n>
            Select {{pageTitle}} Comments
        </h2>
        <div class="button-container">
            <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close('Cross click')">
            </button>
        </div>
    </div>
    <div class="modal-body">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
            <li [ngbNavItem]="1" [destroyOnHide]="true">
                <a ngbNavLink i18n>Subscription</a>
                <ng-template ngbNavContent>
                    <app-subscription-comments-container [rowData]="rowData"
                        (commentsSelected)="onSubscriptionCommentsSelected($event)"></app-subscription-comments-container>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" [destroyOnHide]="true">
                <a ngbNavLink i18n>Location</a>
                <ng-template ngbNavContent>
                    <app-location-comments-container [rowData]="rowDataLocation"
                        (commentsSelected)="onLocationCommentsSelected($event)"></app-location-comments-container>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary mb-3 mb-md-0 me-3 mt-3"
            (click)="activeModal.close('Close click')" i18n id="closeButton">Cancel</button>
        <button type="button" class="btn btn-primary mb-3 mb-md-0 me-3 mt-3" (click)="saveComments()" id="saveButton" i18n>Save</button>
    </div>
</div>
