import { DatePipe, Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Attachment, CreateMessageFlow, CreateMessageFormModel, CreateMessageInfo, ResponseInstructionType, UiFieldReadonlyStates } from '../../models/create-message.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CreateMessageService } from '../../services/create-message.service';
import { MbRole } from 'src/app/models/mb-role';
import { AppConfigService } from 'src/app/helpers/configuration/app-config.service';
import { Guid } from 'guid-typescript';
import { PutReassignRfpRequestEnvelope, RFPStatusChange, RFPStatusChangeRequest, RFPStatusChangeRequestModel, RfpStatusConstants } from 'projects/rfp-list/src/app/models/rfp-detail.model';
import { RfpDetailsService } from 'projects/rfp-list/src/app/services/rfp-details.service';
import { RfpProposalDetailsService } from 'projects/rfp-list/src/app/services/rfp-proposal-details.service';
import { dateTimeFormatOptionsForDateOnly } from 'projects/shared/pagination/src/app/models/dateTimeFormatOptions';
import { RFPError } from 'projects/rfp-list/src/app/constants/rfp-error.constants';
import { toastStyles, toastTypes } from 'src/app/utils/constants';
import { FileUploadModel } from '../../models/file-upload.model';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AttachmentService } from 'src/app/services/attachment.service';
import { CreateAttachmentResponseModel } from 'src/app/models/attachment.model';
import { AlertPopupConfig, AlertPopupService } from 'src/app/services/alert-popup.service';
import { AlertToastComponent } from '../alert-toast/alert-toast.component';
import { SubHeaderActions } from 'src/app/models/subheader-actions.model';
import { HeaderData, SpecialHeaderInfo } from 'src/app/models/ui-header.model';
import { CommentContainerComponent } from 'projects/rfp-list/src/app/proposal-details/comment-container/comment-container.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RichTextComponent } from '../rich-text/rich-text.component';
import { messagePreviewModel } from 'src/app/models/preview-request.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DfModalService } from '@design-factory/design-factory';
import { LocalizedDatePipe } from 'src/app/pipes/localized-date.pipe';
import { NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { LocalizedDateFormatter } from 'src/app/helpers/formatters/localized-date-formatter';
import { LocaleService } from 'src/app/services/locale.service';
import { DateHelper } from 'src/app/helpers/date.helper';


@Component({
  selector: 'app-create-message-page',
  templateUrl: './create-message-page.component.html',
  styleUrls: ['./create-message-page.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: LocalizedDateFormatter },
    { provide: NgbDatepickerI18n, useClass: LocalizedDateFormatter }]
})
export class CreateMessagePageComponent implements OnInit {

  @ViewChild(RichTextComponent) richTextComponent: RichTextComponent;

  rfpId: Guid;
  proposalId: Guid;
  submitted = false;
  dateTimeFormatOptionsForDateOnly = dateTimeFormatOptionsForDateOnly;
  errorMessage: string;
  alertType: any;
  alertStyle: any;
  displayFailureAlert = false;
  uploadSucess1: boolean = false;
  uploadSucess2: boolean = false;
  uploadSucess3: boolean = false;
  uploadFaliure1: boolean = false;
  uploadNotValidate1: boolean = false;
  uploadCommitted1: boolean = false;
  uploadNotValidate2: boolean = false;
  uploadCommitted2: boolean = false;
  uploadNotValidate3: boolean = false;
  uploadCommitted3: boolean = false;
  uploadFaliure2: boolean = false;
  uploadFaliure3: boolean = false;
  fileUploadModel: FileUploadModel;
  files: Attachment[] = [];
  fileSize = 0;
  totalFileSize = 0;
  private statuscheckIntervalForFile2;
  private statuscheckIntervalForFile3;
  private statuscheckIntervalForFile1;
  sendProposalAPICallStartTime: Date;
  sendMessageAPIStartTime: Date;
  message: string;
  htmlContent: string;
  modalUserPicker: NgbModalRef;
  locationId: string;

  actions: SubHeaderActions[] = [];
  headerDataLeft: HeaderData[] = [];
  specialHeaderInfo: SpecialHeaderInfo;
  closeResult?: string;
  headingId = 'modal-title-id';
  previewContent: string;
  @ViewChild('previewModal') previewModal: TemplateRef<any>;
  labelName: string;
  modalContent: SafeUrl;
  errors$ = new Subject<any>();
  placeholder: string;
  placeholderFormat = { de: "DD.MM.YYYY", fr: "DD/MM/YYYY", es: "DD/MM/YYYY", en: "MM/DD/YYYY" };
  defaultStartDate:any;


  constructor(
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private readonly location: Location,
    private appConfigService: AppConfigService,
    private router: Router,
    private readonly alertPopupService: AlertPopupService,
    private createMessageService: CreateMessageService,
    private rfpDetailsService: RfpDetailsService,
    private rfpProposalDetailsService: RfpProposalDetailsService,
    private attachmentService: AttachmentService,
    private modalService: DfModalService,
    protected _sanitizer: DomSanitizer,
    private localizedDatePipe: LocalizedDatePipe,
    private  localeService: LocaleService,
  ) {
    this.LoadAlerts();
    this.maxFileSizeInBytes = parseInt(this.appConfigService.getConfig().maxFileSizeInBytes);
    this.maxFileNameLength = parseInt(this.appConfigService.getConfig().maxFileNameLength);
    this.allowedFileTypes = this.appConfigService.getConfig().allowedFileTypes;
    let currentDate = new Date(Date.now());
    let nextDate = currentDate;
    this.defaultDate = new Date(currentDate).toISOString().slice(0, 10);
    nextDate.setDate(currentDate.getDate() + 1);
    this.minCustomDate = "";
  }

  @ViewChild('attachment1') attachment1;
  @ViewChild('attachment2') attachment2;
  @ViewChild('attachment3') attachment3;

  selectOptions = ['1', '2', '3', '4'];
  dropdownSelected: (number | undefined)[] = [undefined, undefined, undefined];
  turnDownReasons: string[] = [];
  turnDownSelectedReason: string = '';
  turnDownComments: string = `I need to cancel my booking for [date and time]. Something came up, and I can't make it.

  Booking Details:
  
  Booking ID: [Your Booking ID]
  Name: [Your Name]
  Date and Time: [Scheduled Date and Time]
  I know there might be some cancellation rules, and I'm cool with any charges or whatever. Just let me know what I need to do to cancel it.
  
  Sorry for the hassle, and thanks for understanding!`;

  public createMessageTitle: string;

  public uiFieldReadonlyStates: UiFieldReadonlyStates;

  public createMessageForm: UntypedFormGroup;
  public messageFlows = CreateMessageFlow;
  public createMessageDetails: CreateMessageInfo;

  private readonly maxFileSizeInBytes: number;
  public readonly allowedFileTypes: string;
  private readonly maxFileNameLength: number;

  public readonly defaultDate: string;
  public minCustomDate: any;

  private currentDate = new Date();

  public alerts: Array<IAlert> = [];
  private backup: Array<IAlert>;

  public richTextValue: string = "";
  public isReadonlySelfAward: boolean;
  public isMessagePosting: boolean = false;
  public isFileUploading1: boolean = false;
  public isFileUploading2: boolean = false;
  public isFileUploading3: boolean = false;
  public isEndPointIntegrated: boolean = false;
  unSubscribe$ = new Subject();


  ngOnInit(): void {
    this.richTextValue = "";
    this.errors$.pipe(takeUntil(this.unSubscribe$)).subscribe(x => {
      this.alertErrorMessages(x.defaultText, 'error', false);
    });
    this.createMessageDetails = this.createMessageService.getCreateMessageData();
    this.rfpId = this.createMessageDetails.CommonInfo.RfpId
    this.proposalId = this.createMessageDetails.CommonInfo.ProposalId
    this.isReadonlySelfAward = this.createMessageDetails.MessageFlow === this.messageFlows.SelfAward ? true : false;
    this.locationId = sessionStorage.getItem('RfpLocationId');

    if (this.createMessageDetails === null) {
      this.router.navigate(['notfound']);
    }

    //This should be removed once the API is integrated
    if (this.createMessageDetails.MessageFlow === this.messageFlows.Redirect) {
      this.isEndPointIntegrated = true;
    }
    this.setPageTitle();
    this.loadReadOnlyControls();
    this.buildForm();
    this.placeholder = this.placeholderFormat[this.localeService.locale];
    this.defaultStartDate = this.localizedDatePipe.transform(this.createMessageDetails?.SelfAwardInfo?.Date,this.localeService.locale,dateTimeFormatOptionsForDateOnly);
  }

  alertErrorMessages(message, alertType, isCancelButtonVisible?: boolean) {
    return this.alertPopupService.showPopup({ data: { title: 'Error', message: message, isHeaderRequired: true, type: alertType, isCancelButtonVisible: isCancelButtonVisible, okButtonText: 'OK', cancelButtonText: 'Cancel' } } as AlertPopupConfig);
  }

  handleActionsAccess() {

    this.actions = [
      {
        id: "preview",
        displayText: $localize`Preview`,
        buttonCssClass: 'btn btn-outline-primary',
        buttonStyle: 'height: 36px; margin: auto 0; margin-right: 15px;',
        positionInsideDropdownOnLesserScreenWidth: false,
        buttonId:'previewButton',
        positionInsideDropdown: false,
        iconCssClass: '',
        enable: true,
        isDropdown: true,
        dropdownOptions: [{ label: $localize`Email`, icon: "fa-light fa-envelope",buttonId:'emailButton' },
        { label: $localize`PDF`, icon: "fa-light fa-file-pdf",buttonId:'pdfButton' }]
      },
      {
        id: "send",
        displayText: $localize`Send`,
        buttonCssClass: 'btn btn-primary action-button send-button',
        buttonStyle: 'height: 36px; margin: auto 0;',
        positionInsideDropdownOnLesserScreenWidth: false,
        positionInsideDropdown: false,
        iconCssClass: 'fa-light fa-send',
        enable: true,
        isDropdown: false,
        dropdownOptions: []
      },
    ];

    this.specialHeaderInfo = { title: this.createMessageTitle, status: null, messageFlow: this.createMessageDetails?.MessageFlow, backButtonTooltipText: "Back to messages" };
    // set create message data
    this.headerDataLeft = [
      {
        id: `PrimaryContact`, value: (this.createMessageDetails?.CommonInfo?.PrimaryContact === '' || this.createMessageDetails?.CommonInfo?.PrimaryContact === null )? 'N/A' : this.createMessageDetails?.CommonInfo?.PrimaryContact,
        toolTipText: $localize`PrimaryContact`,
        iconCssClass: 'sub-header-field contact-name-field',
        spanCssClass: 'icon fa-light fa-user',
        valueSpanCssClass: "sub-header-data-field",
        isDisplayId: false
      },
      {
        id: `Organization`, value: (this.createMessageDetails?.CommonInfo?.Organization === '' || this.createMessageDetails?.CommonInfo?.Organization === null )? 'N/A' : this.createMessageDetails?.CommonInfo?.Organization,
        toolTipText: $localize`Organization`,
        iconCssClass: 'sub-header-field organization-field',
        spanCssClass: 'icon fa-light fa-building',
        valueSpanCssClass: "sub-header-data-field",
        isDisplayId: false
      },
      {
        id: $localize`Subject`, value: this.createMessageDetails?.CommonInfo?.Subject,
        toolTipText:$localize`Subject`,
        iconCssClass: 'sub-header-field subject-field',
        isDisplayId: true
      },
      {
        id: $localize`Date`, value: this.localizedDatePipe.transform(this.currentDate.toString(), null, dateTimeFormatOptionsForDateOnly),
        toolTipText: $localize`Date`,
        iconCssClass: 'sub-header-field organization-field',
        isDisplayId: true
      },
    ];
  };

  handleActionClick(actionId: string) {
    switch (actionId) {
      case 'send': {
        this.onSubmit(this.createMessageForm);
        break;
      }
      case 'preview': {
        break;
      }
    }
  }

  private buildForm() {
    switch (this.createMessageDetails.MessageFlow) {
      case (this.messageFlows.Proposal):
        this.createMessageForm = this.formBuilder.group({
          To: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.To, disabled: this.uiFieldReadonlyStates.To }, [this.validateEmail()]),
          Cc: new UntypedFormControl({ value: this.createMessageDetails.ProposalInfo?.Cc, disabled: this.uiFieldReadonlyStates.Cc }, [this.validateEmail()]),
          Attachment1: [],
          Attachment2: [],
          Attachment3: []
        });
        break;
      case (this.messageFlows.TurnDown):
        this.turnDownReasons = this.createMessageDetails.TurnDownInfo.TurnDownReasons;
        this.createMessageForm = this.formBuilder.group({
          To: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.To, disabled: this.uiFieldReadonlyStates.To }, [this.validateEmail()]),
          Comments: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.Comments ?? '', disabled: this.uiFieldReadonlyStates.Comment }),
          SystemRecipients: this.createMessageDetails.TurnDownInfo.SystemRecipients,
          TurnDownReasons: '',
          Attachment1: [],
          Attachment2: [],
          Attachment3: []
        });
        break;
      case (this.messageFlows.SelfAward):
        this.createMessageForm = this.formBuilder.group({
          To: new UntypedFormControl(),
          Comments: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.Comments ?? '', disabled: this.uiFieldReadonlyStates.Comment }),
          Attachment1: [],
          Attachment2: [],
          Attachment3: []
        });
        break;
      case (this.messageFlows.Reassign):
        this.createMessageForm = this.formBuilder.group({
          To: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.To, disabled: this.uiFieldReadonlyStates.To }),
          ToEmail: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.ToEmail, disabled: this.uiFieldReadonlyStates.ToEmail }),
          Comments: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.Comments ?? '', disabled: this.uiFieldReadonlyStates.Comment }),
          Attachment1: [],
          Attachment2: [],
          Attachment3: [],
          Location: this.createMessageDetails.CommonInfo.LocationName,
          From: this.createMessageDetails.CommonInfo.From
        });
        break;
      default:
        this.createMessageForm = this.formBuilder.group({
          To: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.To, disabled: this.uiFieldReadonlyStates.To }),
          Comments: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.Comments ?? '', disabled: this.uiFieldReadonlyStates.Comment }),
          Attachment1: [],
          Attachment2: [],
          Attachment3: []
        });
        break;
    }
    this.handleActionsAccess();
  }

  private LoadAlerts() {
    this.alerts.push(
      {
        id: alertErrorCode.fileExtension,
        type: 'danger',
        displayAlert: false,
        message: 'The file extension of one or more attachments is not supported. Supported file extensions include TXT, BMP, JPEG, PNG, PDF, XLS, DOC, DOCX, XLSX, JPG, MSG, PPT, PPTX'
      },
      {
        id: alertErrorCode.totalAttachmentSize,
        type: 'danger',
        displayAlert: false,
        message: 'The size of all attached files exceeds the maximum allowed 15MB.'
      },
      {
        id: alertErrorCode.toField,
        type: 'danger',
        displayAlert: false,
        message: 'To field should be specified'
      },
      {
        id: alertErrorCode.toCcField,
        type: 'danger',
        displayAlert: false,
        message: 'To field should be specified along with CC field.'
      },
      {
        id: alertErrorCode.selectReason,
        type: 'danger',
        displayAlert: false,
        message: 'Please select any of the reason.'
      },
      {
        id: alertErrorCode.awardDataEmpty,
        type: 'danger',
        displayAlert: false,
        message: 'Award Date cannot be blank'
      },
      {
        id: alertErrorCode.awardDateInvalid,
        type: 'danger',
        displayAlert: false,
        message: 'Award date should be past current date'
      },
      {
        id: alertErrorCode.InvalidEmail,
        type: 'danger',
        displayAlert: false,
        message: 'Enter Valid Email Address'
      },
      {
        id: alertErrorCode.fileNameLength,
        type: 'danger',
        displayAlert: false,
        message: 'The file name contains greater than the maximum allowed 255 characters.'
      },
    );
    this.backup = this.alerts.map((alert: IAlert) => Object.assign({}, alert));
  }

  //Method mocking data format for all flows
  private getCreateMessageMockDetails(currentDate: Date): CreateMessageInfo {
    return {
      MessageFlow: this.messageFlows.Proposal, // set according to the parent page
      CommonInfo: {
        To: 'TestUser@amadeus.com',
        From: 'LeagueOfShadows@amadeus.com',
        Subject: 'Subject Test',
        Organization: 'Organization Test',
        PrimaryContact: 'Primary Contact Test',
        Comments: this.createMessageDetails.MessageFlow === this.messageFlows.TurnDown ? this.turnDownComments : '',
        RfpId: this.rfpId,
        Person: null
      },
      ProposalInfo: {
        Cc: 'CC Test',
        Date: this.datePipe.transform(currentDate, 'MM/dd/yyyy'),
        AllowResponseInstructionOverride: false,
        RichTextEditorData: '',
        ResponseInstructionType: ResponseInstructionType.RespondToPrimaryContact
      },
      UserRole: MbRole.Supervisor,
      TurnDownInfo: {
        TurnDownReasons: this.turnDownReasons,
        SystemRecipients: 'System recipients test',
      },
      SelfAwardInfo: {
        Date: this.datePipe.transform(currentDate, 'MM/dd/yyyy'),
      }
    }
  }

  async onSubmit(form: UntypedFormGroup) {
    this.isMessagePosting = true;
    this.errorMessage = "";
    if (this.createMessageDetails.MessageFlow === this.messageFlows.TurnDown || this.createMessageDetails.MessageFlow === this.messageFlows.Proposal) {
      this.submitted = true;
      if (this.createMessageForm.invalid) {
        this.isMessagePosting = false;
        this.displayErrorMessage(alertErrorCode.InvalidEmail);
        return;
      }
    }
    if (
      this.validateInputFields(form) &&
      this.validateTurnDownReason()
    ) {
      let formData = this.getFormModel(form, this.files);
      switch (this.createMessageDetails.MessageFlow) {
        case this.messageFlows.Proposal: {
          await this.sendProposal(form, this.files);
          break;
        }
        case this.messageFlows.TurnDown: {
          await this.UpdateRFPStatus(formData, form, RfpStatusConstants.TurnedDown, this.files);
          break;
        }
        case this.messageFlows.SelfAward: {
          await this.UpdateRFPStatus(formData, form, RfpStatusConstants.SelfAwarded, this.files);
          break;
        }
        case this.messageFlows.Reassign: {
          await this.ReassignRFPOwner(form, RfpStatusConstants.Reassign, this.files);
          break;
        }
        default: {
          break;
        }
      }

    } else {
      this.isMessagePosting = false;
      //this.createMessageDetails = this.getCreateMessageMockDetails(this.currentDate);
    }

  }

  private async UpdateRFPStatus(formData: CreateMessageFormModel, form: UntypedFormGroup, rfpStatus: string, files: Attachment[]) {
    let rfpStatusChangeObj: RFPStatusChange = {
      Status: rfpStatus,
      Comment: formData.Comments,
      ReasonId: formData.turnDownReason,
      StartDate: formData.SelfAwardDate,
    };

    let rfpStatusChangeRequest: RFPStatusChangeRequest = {
      rfpStatusChange: rfpStatusChangeObj,
    };
    let selfAwardRequestModel: RFPStatusChangeRequestModel = {
      data: rfpStatusChangeRequest,
    };
    await this.rfpDetailsService
      .updateRfpStatus(this.rfpId.toString(), selfAwardRequestModel)
      .toPromise()
      .then((response) => {
        if (response) {
          this.PostMessage(form, files);
        } else {
          this.isMessagePosting = false;
          this.displayError($localize`Something went wrong. Please try again later.`);
          return;
        }
      })
      .catch((err) => {
        this.isMessagePosting = false;
        this.handleUpdateServiceError(err);
        return;
      });
  }

  private async ReassignRFPOwner(form: UntypedFormGroup, rfpStatus: string, files: Attachment[]) {
    var reassignmentrequestModel: PutReassignRfpRequestEnvelope = {
      data: {
        assignmentMethod: rfpStatus,
        newUserId: this.createMessageDetails.CommonInfo.ToId.toString()
      }
    }
    await this.rfpDetailsService
      .reassignRfp(this.rfpId.toString(), reassignmentrequestModel)
      .toPromise()
      .then((response) => {
        if (response) {
          this.PostMessage(form, files);
        } else {
          this.isMessagePosting = false;
          this.displayError($localize`Something went wrong. Please try again later.`);
          return;
        }
      })
      .catch((err) => {
        this.isMessagePosting = false;
        this.handleUpdateServiceError(err);
        return;
      });
  }

  private PostMessage(form: FormGroup, files: Attachment[]) {
    this.sendMessageAPIStartTime = new Date();
    this.createMessageService.CreateMessage(this.getFormModel(form, files)).then(response => {
      if (response) {
        console.log('Send Message API call duration: ', (new Date().getTime() - this.sendMessageAPIStartTime.getTime()) / 1000 + ' seconds');
        this.isMessagePosting = false;
        switch (this.createMessageDetails.MessageFlow) {
          case this.messageFlows.Proposal:
            setTimeout(() => {
              this.location.back();
            }, 2000);
            console.log('Send proposal operation overall duration: ', (new Date().getTime() - this.sendProposalAPICallStartTime.getTime()) / 1000 + ' seconds');
            this.message = $localize`Proposal sent successfully!`;
            this.alertType = toastTypes.success;
            AlertToastComponent.showToast(this.message, this.alertType, toastStyles.small);
            break;
          case this.messageFlows.UpdateRfp:
            setTimeout(() => {
              this.location.back();
            }, 2000);
            this.message = $localize`RFP updated successfully!`;
            this.alertType = toastTypes.success;
            AlertToastComponent.showToast(this.message, this.alertType, toastStyles.small);
            break;
          case this.messageFlows.TurnDown:
            setTimeout(() => {
              this.router.navigate(['/rfplist']);
            }, 2000);
            this.message = $localize`RFP turned down successfully!`;
            this.alertType = toastTypes.success;
            AlertToastComponent.showToast(this.message, this.alertType, toastStyles.small);
            break;
          case this.messageFlows.SelfAward:
            setTimeout(() => {
              this.router.navigate(['/rfplist']);
            }, 2000);
            this.message = $localize`RFP awarded to self successfully!`;
            this.alertType = toastTypes.success;
            AlertToastComponent.showToast(this.message, this.alertType, toastStyles.small);
            break;
          case this.messageFlows.Redirect:
            setTimeout(() => {
              this.router.navigate(['/rfplist']);
            }, 2000);
            this.message = $localize`RFP redirected successfully!`;
            this.alertType = toastTypes.success;
            AlertToastComponent.showToast(this.message, this.alertType, toastStyles.small);
            break;
          case this.messageFlows.Reassign:
            setTimeout(() => {
              this.router.navigate(['/rfplist']);
            }, 2000);
            this.message = $localize`RFP reassigned successfully!`;
            this.alertType = toastTypes.success;
            AlertToastComponent.showToast(this.message, this.alertType, toastStyles.small);
            break;
        }
      }
      else {
        this.isMessagePosting = false;
        this.displayError($localize`Something went wrong. Please try again later.`);
        return;
      }
    }).catch(err => {
      this.isMessagePosting = false;
      this.handleUpdateServiceError(err);
      return;
    });
  }

  getFormModel(form: UntypedFormGroup, files: Attachment[]): CreateMessageFormModel {
    let formModel: CreateMessageFormModel;
    switch (this.createMessageDetails.MessageFlow) {
      case (this.messageFlows.Proposal):
        formModel = {
          flow: this.createMessageDetails.MessageFlow,
          To: form.get('To').value,
          Cc: form.get('Cc')?.value,
          RichText: this.richTextValue,
          Attachments: files,
          proposalDate: new Date(Date.now()).toDateString(),
          Comments: form.get('Comments')?.value ? form.get('Comments')?.value : null,
          SelfAwardDate: null,
          turnDownReason: null,
          systemRecipients: null,
          proposalId: this.proposalId,
          ProposalLastSentDate: this.createMessageDetails.CommonInfo.ProposalLastSentDate
        }
        break;
      case (this.messageFlows.SelfAward):
        var defaultDate = document.getElementById("DefaultSelfAwardDate") as HTMLInputElement;
        formModel = {
          flow: this.createMessageDetails.MessageFlow,
          To: form.get('To').value,
          Attachments: files,
          SelfAwardDate: defaultDate.checked ? defaultDate.value : DateHelper.mapNgbDateToUTCDate(this.minCustomDate),
          Comments: form.get('Comments')?.value,
          Cc: null,
          RichText: null,
          turnDownReason: null,
          systemRecipients: null,
          rfpId: this.rfpId
        }
        break;
      case (this.messageFlows.TurnDown):
        formModel = {
          flow: this.createMessageDetails.MessageFlow,
          To: form.get('To').value,
          Cc: null,
          Attachments: files,
          Comments: form.get('Comments')?.value,
          systemRecipients: form.get('SystemRecipients').value,
          turnDownReason: this.turnDownSelectedReason,
          SelfAwardDate: null,
          RichText: null,
          rfpId: this.rfpId
        }
        break;
      case (this.messageFlows.Reassign):
        formModel = {
          flow: this.createMessageDetails.MessageFlow,
          To: form.get('To').value,
          ToEmail: form.get('ToEmail').value,
          Cc: null,
          Attachments: files,
          Comments: form.get('Comments')?.value,
          systemRecipients: null,
          turnDownReason: null,
          SelfAwardDate: null,
          RichText: null,
          rfpId: this.rfpId,
          From: form.get('From').value,
          LocationName: form.get('Location').value
        }
        break;
      default:
        formModel = {
          flow: this.createMessageDetails.MessageFlow,
          To: form.get('To').value,
          Comments: form.get('Comments')?.value,
          Attachments: files,
          Cc: null,
          RichText: null,
          SelfAwardDate: null,
          systemRecipients: null,
          turnDownReason: null,
        }
        break;
    }
    formModel.Person = this.createMessageDetails.CommonInfo.Person;
    formModel.From = this.createMessageDetails.CommonInfo.From;
    formModel.Organization = this.createMessageDetails.CommonInfo.Organization;
    formModel.Subject = this.createMessageDetails.CommonInfo.Subject;
    return formModel;
  }

  validateInputFields(form: UntypedFormGroup): boolean {
    const element = <HTMLElement> document.getElementById('toTextField');
    if (this.createMessageDetails.MessageFlow == this.messageFlows.Proposal) {
      if (!(form.get('To').value)) {
        element.style.borderColor = "red";
        element.blur();
        this.displayErrorMessage(alertErrorCode.toCcField);
        return false;
      }
    }
    if (this.createMessageDetails.MessageFlow == this.messageFlows.TurnDown) {
      if (!(form.get('To').value)) {
        element.style.borderColor = "red";
        element.blur();
        this.displayErrorMessage(alertErrorCode.toField);
        return false;
      }
    }
    if (!(form.get('To').value) && this.createMessageDetails.MessageFlow !== this.messageFlows.SelfAward) {
      this.displayErrorMessage(alertErrorCode.toField);
      return false;
    }
    if (this.createMessageDetails.MessageFlow == this.messageFlows.Reassign) {
      if (!(form.get('To').value)) {
        element.style.borderColor = "red";
        element.blur();
        this.displayErrorMessage(alertErrorCode.toField);
        return false;
      }
    }
    if (this.createMessageDetails.MessageFlow === this.messageFlows.SelfAward && !this.checkSelfAwardDate()) {
      var customDate = document.getElementById("CustomSelfAwardDate") as HTMLInputElement;
      if (!customDate.value) {
        customDate.style.borderColor = "red";
        customDate.blur();
        this.displayErrorMessage(alertErrorCode.awardDataEmpty);
        return false;
      }
      var parsedDate = new Date(Date.parse(customDate.value));
      if (this.currentDate >= parsedDate) {
        customDate.style.borderColor = "red";
        customDate.blur();
        this.displayErrorMessage(alertErrorCode.awardDateInvalid);
        return false;
      }
    }
    return true;
  }

  validateFileNameLength(file: File): boolean {
    if (file.name.length > this.maxFileNameLength) {
      this.totalFileSize -= this.fileSize;
      this.displayErrorMessage(alertErrorCode.fileNameLength);
      return false;
    }
    this.alerts.filter(alert => alert.id === alertErrorCode.fileNameLength)[0].displayAlert = false;
    return true;
  }

  validateFilesSize(): boolean {
    if (this.totalFileSize > this.maxFileSizeInBytes) {
      this.totalFileSize -= this.fileSize;
      this.displayErrorMessage(alertErrorCode.totalAttachmentSize);
      return false;
    }
    this.alerts.filter(alert => alert.id === alertErrorCode.totalAttachmentSize)[0].displayAlert = false;
    return true;
  }

  validateFileType(file: File): boolean {
    if (file) {
      const splitFile = file.name.split(/(?=\.)/);
      if (splitFile.length > 1) {
        const extension = splitFile.pop()?.toLowerCase();
        const allowedExtensions = this.allowedFileTypes.split(',').map((item) => {
          this.alerts.filter(alert => alert.id === alertErrorCode.fileExtension)[0].displayAlert = false;
          return item.trim();
        });
        if (allowedExtensions.some((value) => value.toLowerCase() === extension)) {
          return true;
        } else {
          this.totalFileSize -= this.fileSize;
          this.displayErrorMessage(alertErrorCode.fileExtension);
          return false;
        }
      }
      else {
        this.displayErrorMessage(alertErrorCode.fileExtension);
        return false;
      }
    }
  }

  validateTurnDownReason(): boolean {
    if (this.createMessageDetails.MessageFlow == this.messageFlows.TurnDown && this.turnDownSelectedReason.length == 0) {
      this.displayErrorMessage(alertErrorCode.selectReason);
      const element = <HTMLElement> document.getElementsByName('turnDownReason')[0];
      element.className = element.className + " TurnDownReasonsValidate";

      return false;
    }
    return true;
  }

  validateReassignReason(): boolean {
    if (this.createMessageDetails.MessageFlow == this.messageFlows.Reassign && this.turnDownSelectedReason.length == 0) {
      this.displayErrorMessage(alertErrorCode.selectReason);
      return false;
    }
    return true;
  }

  displayErrorMessage(errorCode: number) {
    const filteredAlerts: IAlert[] = this.alerts.filter(alert => alert.id === errorCode);
    if (filteredAlerts.length > 0) {
      filteredAlerts[0].displayAlert = true;
    }
  }

  onReasonSelected() {
    this.alerts.filter(alert => alert.id === alertErrorCode.selectReason)[0].displayAlert = false;
    const element = <HTMLElement> document.getElementsByName('turnDownReason')[0];
    element.className = element.className.replace("TurnDownReasonsValidate","");
  }

  onToValueChange() {
    const element = <HTMLElement> document.getElementById('toTextField');
    element.style.borderColor = "";
    if (this.createMessageDetails.MessageFlow == this.messageFlows.Proposal)
      this.alerts.filter(alert => alert.id === alertErrorCode.toCcField)[0].displayAlert = false;
    else {
      this.alerts.filter(alert => alert.id === alertErrorCode.toField)[0].displayAlert = false;
      this.alerts.filter(alert => alert.id === alertErrorCode.InvalidEmail)[0].displayAlert = false;
    }
  }

  async updateFile(event: Event, attachmentNo: string) {
    const element = <HTMLElement> document.getElementsByClassName('clsAttachment'+attachmentNo)[0];
    let file = (event.currentTarget as HTMLInputElement).files[0];
    const unsubscribeForFile1$ = new Subject();
    const unsubscribeForFile2$ = new Subject();
    const unsubscribeForFile3$ = new Subject();

    if (file.type === '' && file.name.endsWith('.msg')) {
      file = new File([file], file.name, { type: 'application/vnd.ms-outlook' });
    }

    let fileUploadModel = {
      contentType: file.type,
      url: file.webkitRelativePath,
      name: file.name,
      content: ""
    };
    this.fileSize = file.size;
    this.totalFileSize += this.fileSize;

    if (this.validateFileType(file) && this.validateFileNameLength(file) && this.validateFilesSize()) {
      const fileContents = await this.readUploadedFileAsDataURL(file);
      let fileContentString = fileContents.toString();
      fileUploadModel.content = fileContentString.substring(fileContentString.indexOf(',') + 1);

      if (attachmentNo === '1') {
        element.style.borderColor = "";
        this.uploadSucess1 = false;
        this.uploadFaliure1 = false;
        this.isFileUploading1 = true;
        try {
          await this.createMessageService
            .createAttachment(fileUploadModel)
            .then(response => {
              const fileUploadStatusResponse = response as CreateAttachmentResponseModel;
              this.statuscheckIntervalForFile1 =
                interval(1000)
                  .pipe(takeUntil(unsubscribeForFile1$))
                  .subscribe(async x => {
                    try {
                      await this.attachmentService.getAttachmentStatusDetails(fileUploadStatusResponse.data.reference.id)
                        .toPromise()
                        .then((response) => {
                          if (response) {
                            if (response.data.status === 'Ok') {
                              if (!this.statuscheckIntervalForFile1.closed === true) {
                                this.createMessageForm.patchValue({ Attachment1: file });
                                this.uploadSucess1 = true;
                                this.isFileUploading1 = false;
                                this.files.push({ id: response.data.id });
                                this.statuscheckIntervalForFile1.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile1.unsubscribe();
                              }

                            }
                            else if (response.data.status === 'Failed') {
                              if (!this.statuscheckIntervalForFile1.closed === true) {
                                this.uploadFaliure1 = true;
                                this.isFileUploading1 = false;
                                this.statuscheckIntervalForFile1.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile1.unsubscribe();
                              }
                            }
                            else if (response.data.status === 'NotValid') {
                              if (!this.statuscheckIntervalForFile1.closed === true) {
                                this.isFileUploading1 = false;
                                this.uploadNotValidate1 = true;
                                this.statuscheckIntervalForFile1.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile1.unsubscribe();
                              }
                            }
                            else if (response.data.status === 'Committed') {
                              if (!this.statuscheckIntervalForFile1.closed === true) {
                                this.isFileUploading1 = false;
                                this.uploadCommitted1 = true;
                                this.statuscheckIntervalForFile1.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile1.unsubscribe();
                              }
                            }
                          }
                        }
                          ,
                          error => {
                            console.log("Error occourd for getting file status: " + error);
                          }
                        );
                    }
                    catch (e) {
                      this.handleUpdateServiceError(e);
                      return;
                    }
                  });
            });
        } catch (e) {
          this.handleUpdateServiceError(e);
          return;
        }
      }
      else if (attachmentNo === '2') {
        element.style.borderColor = "";
        this.uploadSucess2 = false;
        this.uploadFaliure2 = false;
        this.isFileUploading2 = true;
        try {
          await this.createMessageService
            .createAttachment(fileUploadModel)
            .then(async response => {
              const fileUploadStatusResponse = response as CreateAttachmentResponseModel;
              this.statuscheckIntervalForFile2 =
                interval(1000)
                  .pipe(takeUntil(unsubscribeForFile2$))
                  .subscribe(async x => {
                    try {
                      await this.attachmentService.getAttachmentStatusDetails(fileUploadStatusResponse.data.reference.id)
                        .toPromise()
                        .then((response) => {
                          if (response) {
                            if (response.data.status === 'Ok') {
                              if (!this.statuscheckIntervalForFile2.closed === true) {
                                this.createMessageForm.patchValue({ Attachment2: file });
                                this.uploadSucess2 = true;
                                this.isFileUploading2 = false;
                                this.files.push({ id: response.data.id });
                                this.statuscheckIntervalForFile2.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile2.unsubscribe();
                              }
                            }
                            else if (response.data.status === 'Failed') {
                              if (!this.statuscheckIntervalForFile2.closed === true) {
                                this.uploadFaliure2 = true;
                                this.isFileUploading2 = false;
                                this.statuscheckIntervalForFile2.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile2.unsubscribe();
                              }
                            }
                            else if (response.data.status === 'NotValid') {
                              if (!this.statuscheckIntervalForFile2.closed === true) {
                                this.isFileUploading2 = false;
                                this.uploadNotValidate2 = true;
                                this.statuscheckIntervalForFile2.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile2.unsubscribe();
                              }
                            }
                            else if (response.data.status === 'Committed') {
                              if (!this.statuscheckIntervalForFile2.closed === true) {
                                this.isFileUploading2 = false;
                                this.uploadCommitted2 = true;
                                this.statuscheckIntervalForFile2.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile2.unsubscribe();
                              }
                            }
                          }
                        }
                          ,
                          error => {
                            console.log("Error occourd for getting file status: " + error);
                          }
                        );
                    }
                    catch (e) {
                      this.handleUpdateServiceError(e);
                      return;
                    }
                  });

            });
        } catch (e) {
          this.handleUpdateServiceError(e);
          return;
        }
      }
      else {
        element.style.borderColor = "";
        this.uploadSucess3 = false;
        this.uploadFaliure3 = false;
        this.isFileUploading3 = true;
        try {
          await this.createMessageService
            .createAttachment(fileUploadModel)
            .then(async response => {
              const fileUploadStatusResponse = response as CreateAttachmentResponseModel;
              this.statuscheckIntervalForFile3 =
                interval(1000)
                  .pipe(takeUntil(unsubscribeForFile3$))
                  .subscribe(async x => {
                    try {
                      await this.attachmentService.getAttachmentStatusDetails(fileUploadStatusResponse.data.reference.id)
                        .toPromise()
                        .then((response) => {
                          if (response) {
                            if (response.data.status === 'Ok') {
                              if (!this.statuscheckIntervalForFile3.closed === true) {
                                this.createMessageForm.patchValue({ Attachment3: file });
                                this.uploadSucess3 = true;
                                this.isFileUploading3 = false;
                                this.files.push({ id: response.data.id });
                                this.statuscheckIntervalForFile3.next(); // unsubscribe not working as expected for subscription
                                this.statuscheckIntervalForFile3.unsubscribe();
                              }
                            }
                            else if (response.data.status === 'Failed') {
                              if (!this.statuscheckIntervalForFile3.closed === true) {
                                this.uploadFaliure3 = true;
                                this.isFileUploading3 = false;
                                this.statuscheckIntervalForFile3.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile3.unsubscribe();
                              }
                            }
                            else if (response.data.status === 'NotValid') {
                              if (!this.statuscheckIntervalForFile3.closed === true) {
                                this.isFileUploading3 = false;
                                this.uploadNotValidate3 = true;
                                this.statuscheckIntervalForFile3.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile3.unsubscribe();
                              }
                            }
                            else if (response.data.status === 'Committed') {
                              if (!this.statuscheckIntervalForFile3.closed === true) {
                                this.isFileUploading3 = false;
                                this.uploadCommitted3 = true;
                                this.statuscheckIntervalForFile3.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile3.unsubscribe();
                              }
                            }
                          }
                        }
                          ,
                          error => {
                            console.log("Error occourd for getting file status: " + error);
                          }
                        );
                    }
                    catch (e) {
                      this.handleUpdateServiceError(e);
                      return;
                    }
                  });
            });
        } catch (e) {
          this.handleUpdateServiceError(e);
          return;
        }
      }
    } else {
      if (attachmentNo === '1') {
        element.style.borderColor = "red";
        element.blur();
        this.createMessageForm.patchValue({ Attachment1: null });
        this.attachment1.nativeElement.value = null;
        this.uploadSucess1 = false;
        this.uploadFaliure1 = false;
      }
      else if (attachmentNo === '2') {
        element.style.borderColor = "red";
        element.blur();
        this.createMessageForm.patchValue({ Attachment2: null });
        this.attachment2.nativeElement.value = null;
        this.uploadSucess2 = false;
        this.uploadFaliure2 = false;
      }
      else if (attachmentNo === '3') {
        element.style.borderColor = "red";
        element.blur();
        this.createMessageForm.patchValue({ Attachment3: null });
        this.attachment3.nativeElement.value = null;
        this.uploadSucess3 = false;
        this.uploadFaliure3 = false;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.statuscheckIntervalForFile1) {
      this.statuscheckIntervalForFile1.unsubscribe();
    }
    if (this.statuscheckIntervalForFile2) {
      this.statuscheckIntervalForFile2.unsubscribe();
    }
    if (this.statuscheckIntervalForFile3) {
      this.statuscheckIntervalForFile3.unsubscribe();
    }
  }

  readUploadedFileAsDataURL(inputFile) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(inputFile);
    });
  }

  loadReadOnlyControls() {
    switch (this.createMessageDetails.MessageFlow) {
      case (this.messageFlows.Proposal):
        this.uiFieldReadonlyStates = {
          To: false, //TODO: Add user role check if it is present in classic MB
          Cc: false, //TODO: Add user role check if it is present in classic MB
        }
        break;
      case (this.messageFlows.TurnDown):
        this.uiFieldReadonlyStates = {
          To: false,
          Comment: false
        }
        break;
      default:
        this.uiFieldReadonlyStates = {
          To: true,
          Comment: false
        };
        break;
    }
  }

  setPageTitle() {
    switch (this.createMessageDetails.MessageFlow) {
      case (this.messageFlows.TurnDown):
        this.createMessageTitle = $localize`Message For Turn Down`;
        break;
      case (this.messageFlows.Redirect):
        this.createMessageTitle = $localize`Message For Redirect`;
        break;
      case (this.messageFlows.Proposal):
        this.createMessageTitle = $localize`Message For Proposal`;
        break;
      case (this.messageFlows.SelfAward):
        this.createMessageTitle = $localize`Message For Self Award`;
        break;
      case (this.messageFlows.Reassign):
        this.createMessageTitle = $localize`Message For Reassign`;
        break;
      case (this.messageFlows.NewRfp):
        this.createMessageTitle = $localize`Message For New RFP`;
        break;
      case (this.messageFlows.UpdateRfp):
        this.createMessageTitle = $localize`Message For Update RFP`;
        break;
      default:
        this.router.navigate(['notfound']);
        break
    }
  }

  onBackButtonClicked() {
    this.location.back();
  }
  public closeAlert(alert: IAlert) {

    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
    alert.displayAlert = false;
    this.alerts.push(alert);
  }

  public reset() {
    this.alerts = this.backup.map((alert: IAlert) => Object.assign({}, alert));
  }

  checkSelfAwardDate(): boolean {
    var element = document.getElementById('CustomSelfAwardDateId') as HTMLInputElement;
    return element.checked ? null : true;
  }

  onDateTypeChange(e) {
    var customDate = document.getElementById("CustomSelfAwardDate") as HTMLInputElement;
    customDate.style.border="";
    if (e.srcElement.id === 'DefaultSelfAwardDate') {
      this.alerts.filter(alert => alert.id === alertErrorCode.awardDataEmpty)[0].displayAlert = false;
    }
  }
  onCustomSelfAwardDateSelect(e) {
    var customDate = document.getElementById("CustomSelfAwardDate") as HTMLInputElement;
    customDate.style.border="";
    this.alerts.filter(alert => alert.id === alertErrorCode.awardDataEmpty)[0].displayAlert = false;
  }
  // validating array of emails
  validateEmail() {
    return (control: UntypedFormControl) => {
      var emails = control.value.split(";");
      var regex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      let validEmail = true;

      for (var i = 0; i < emails.length; i++) {
        emails[i] = emails[i].trim();
        if (emails[i] != "") {
          if (!regex.test(emails[i])) {
            validEmail = false;
          }
        }
      }
      return validEmail ? null : { invalid_email: true };
    };
  }

  async sendProposal(formData, files) {
    this.sendProposalAPICallStartTime = new Date();
    await this.rfpProposalDetailsService
      .sendProposal(this.rfpId)
      .toPromise()
      .then((response) => {
        if (response) {
          console.log('sendProposal API call duration: ', (new Date().getTime() - this.sendProposalAPICallStartTime.getTime()) / 1000 + ' seconds');
          this.PostMessage(formData, files);
        } else {
          this.isMessagePosting = false;

          this.displayError($localize`Something went wrong. Please try again later.`);
          return;
        }
      })
      .catch((err) => {
        this.isMessagePosting = false;
        this.handleUpdateServiceError(err);
        return;
      });
  }

  displayError(errorMessage: string) {
    this.errorMessage = errorMessage;
    this.displayFailureAlert = true;
    this.alertType = toastTypes.danger;
    this.alertStyle = toastStyles.big;
  }

  onPreviewClick(event) {
    if (event === 0) {
      this.openPreviewModal(this.previewModal, 'Email')
    } else {
      this.openPreviewModal(this.previewModal, 'PDF')
    }
  }

  openInNewTab(previewContent) {
    const newWindow = window.open();
    if (newWindow) {
      newWindow.document.write(previewContent);
      newWindow.document.close();
    }
  }

  openPreviewModal(content: TemplateRef<any>, label: string) {
    const previewRequest: messagePreviewModel = {
      messagePreview: {
        parentType: "Proposal",
        parentId: this.proposalId.toString(),
        body: this.richTextValue
      }
    }
    this.isMessagePosting = true;
    this.createMessageService.getPreview({ data: previewRequest }, label.toLowerCase()).then(response => {
      if (response) {
        this.previewContent = response.body.data.content;
        this.openInNewTab(this.previewContent);
        this.isMessagePosting = false;
      }
    }).catch(err => {
      this.handleRfpDetailServiceError(err);
      this.isMessagePosting = false;
    });

  }

  handleRfpDetailServiceError(err: any) {

    const errors = err?.error?.errors?.map(x => x.code);
    if (!errors || !errors.length) return;

    if (errors.some(x => x === RFPError.UNAUTHORISED)) {
      this.errors$.next({ translateKey: 'preview-txt-unauthorisedmessage', defaultText: 'User is not authorised to view the Preview.' });
      return;
    }

    this.handleServiceError(errors);
  }

  handleServiceError(errors: any): void {

    errors.forEach(e => {
      switch (e) {
        case RFPError.BAD_REQUEST: this.errors$.next({ translateKey: 'preview-txt-badrequestmessage', defaultText: 'Oops, something went wrong. Please check your input.' }); break;
        case RFPError.INTERNAL_SERVER_ERROR: this.errors$.next({ translateKey: 'preview-txt-internalservererror', defaultText: 'Something went wrong. Please try again later.' }); break;
        default: this.errors$.next({ translateKey: 'preview-txt-internalservererror', defaultText: 'Something went wrong. Please try again later.' });
      }
    });
  }


  handleUpdateServiceError(err: any) {
    let message: string;
    const errors = err?.error?.errors?.filter(x => x.code);
    if (!errors || !errors.length) return;

    errors.forEach(e => {
      switch (e.code) {
        case RFPError.BAD_REQUEST:
          message = $localize`Oops, something went wrong. Please check your input.`;
          break;
        case RFPError.INTERNAL_SERVER_ERROR:
          message = $localize`Something went wrong. Please try again later.`;
          break;
        default:
          message = $localize`Something went wrong. Please try again later.`;
      }
    });

    message = message == "" || message == null ? $localize`Something went wrong. Please try again later.` : message;
    this.displayError(message);
  }

  openModal() {
    this.modalUserPicker = this.modalService.open(CommentContainerComponent, {
      size: "xl",
    });
    this.modalUserPicker.componentInstance.pageTitle = "Proposal";
    this.modalUserPicker.componentInstance.locationId = this.locationId;
    this.modalUserPicker.result.then((result) => {
      if (this.richTextValue) {
        this.richTextValue += "\n";
      }
      result.forEach(element => {
        this.richTextValue += element.content + "\n";
      });
      this.onChangesOnRTE(this.richTextValue.replace(/\n/g, '<br>'));
    });
  }

  clearAllComments() {
    this.richTextComponent.value = "";
    this.richTextValue = "";
  }

  onChangesOnRTE(value: any) { // on changes on Rich Text Editor
    this.richTextComponent.value = value;
    this.richTextValue = value;
  }

  disableDate() {
    return false;
  }

  clearDate(dpin: any) {
    dpin.value = null;
    this.minCustomDate = null;
    this.onCustomSelfAwardDateSelect(null);
  }
}

export interface IAlert {
  id: number;
  type: string;
  message?: string;
  displayAlert: boolean;
}

export enum alertErrorCode {
  fileExtension = 1,
  totalAttachmentSize,
  toCcField,
  selectReason,
  awardDataEmpty,
  awardDateInvalid,
  toField,
  InvalidEmail,
  fileNameLength
}


