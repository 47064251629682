import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { SubHeaderActions } from 'src/app/models/subheader-actions.model';
import { HeaderData, SpecialHeaderInfo } from 'src/app/models/ui-header.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnDestroy, OnChanges{
  @Input() specialHeaderInfo: SpecialHeaderInfo;
  @Input() actions: SubHeaderActions[] = [];  // List of actions/buttons
  @Input() headerDataLeft: HeaderData[] = [];
  @Input() headerDataRight: HeaderData[] = [];
  @Input() isMessagePosting:boolean;

  @Output() onBackClick = new EventEmitter<void>();
  @Output() onActionClick = new EventEmitter<string>();
  @Output() onPreviewClick = new EventEmitter<any>();
  headerActions: SubHeaderActions[] = [];


  constructor(private cdr: ChangeDetectorRef) {}
  
  backClicked() {
    this.onBackClick.emit();
  }

  actionClicked(actionId: string) {
    this.onActionClick.emit(actionId);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes['actions'] && changes['actions']?.currentValue) {
      this.headerActions = [...this.actions.map(x => ({...x}))];
      this.onWindowResize({target: window});
    }
  }

  ngOnDestroy(): void {
    this.headerActions = [];
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(data) {
    if(data.target.innerWidth <= 960) {
      this.headerActions = this.headerActions.map(x => {
        if (x.positionInsideDropdownOnLesserScreenWidth) {
            x.positionInsideDropdown = true;
        }
        return x; // Return the updated object
    });

    // Assign a new array reference to force change detection
    this.headerActions = [...this.headerActions];
      // Trigger change detection manually
      this.cdr.detectChanges();
    } else {
      this.headerActions = [...this.actions.map(x => ({...x}))];
      this.cdr.detectChanges();
    }
  }

  dropDownOptionSelected: (any | undefined)[] = [undefined, undefined, undefined];
  dropdownSelect(dropdownId: number, id: number) {
    this.dropDownOptionSelected[dropdownId] = id;
  }

  isAnyDropdownEnabled(): boolean {
    return this.headerActions.some(action => action.positionInsideDropdown === true);
  }

  onMouseOver(element: HTMLElement, field: string) {
    var k = this.specialHeaderInfo;
    let valueElement = element.children[1] as HTMLElement;
    let value:any = element.children[1].textContent;
    const checkdisplayCss = window.getComputedStyle(valueElement).getPropertyValue('display');
    switch (field) {
      case 'PrimaryContact':
        this.headerDataLeft.find(item => item.id === "PrimaryContact").toolTipText = this.checkIfTextIsOverflowing(element, checkdisplayCss) ? $localize`Primary Contact` + ": " + value : $localize`Primary Contact`;
        break;
      case 'Organization':
        this.headerDataLeft.find(item => item.id === "Organization").toolTipText = this.checkIfTextIsOverflowing(element, checkdisplayCss) ? $localize`Organization` + ": " + value : $localize`Organization`;
        break;
      case 'PeakRooms':
        this.headerDataLeft.find(item => item.id === "PeakRooms").toolTipText = this.checkIfTextIsOverflowing(element, checkdisplayCss) ? $localize`Peak rooms` + ": " + (value === 0 ? '0' : value) : $localize`Peak rooms`;
        break;
        case 'Subject':
        this.headerDataLeft.find(item => item.id === $localize`Subject`).toolTipText = this.checkIfTextIsOverflowing(element, checkdisplayCss) ? $localize`Subject` + ": " + (value === null ? 'N/A' : value) : $localize`Subject`;
        break;
        case 'Date':
          this.headerDataLeft.find(item => item.id ===  $localize`Date`).toolTipText = this.checkIfTextIsOverflowing(element, checkdisplayCss) ? $localize`Date` + ": " + value : $localize`Date`;
          break;
  }
}
 
  checkIfTextIsOverflowing = (element: HTMLElement, valueDisplayProperty) => { return ((element.offsetWidth < element.scrollWidth) || valueDisplayProperty === 'none') };

  openPreviewModal(id) {
    this.onPreviewClick.emit(id);
  }
  
}


