import { CopyrightComponent } from "../components/copyright/copyright.component";
import { PrivacyTermsComponent } from "../components/privacy-terms/privacy-terms.component";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { SpinnerComponent } from "../components/spinner/spinner.component";
import { PageHeaderComponent } from "../components/page-header/page-header.component";
import { PageSubheaderComponent } from "../components/page-subheader/page-subheader.component";
import { LocalizedCurrencyPipe } from "../pipes/localized-currency.pipe";
import { LocalizedDatePipe } from "../pipes/localized-date.pipe";
import { LocalizedDateWithoutTimezonePipe } from "../pipes/localized-date-without-timezone.pipe";
import { CountryPipe } from "../pipes/country.pipe";
import { AlertInfoComponent } from "../components/alert-popup/alert-info.component";
import { AlertPopupService } from "src/app/services/alert-popup.service";
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { RichTextComponent } from "../components/rich-text/rich-text.component";
import { GridDateCellEditorComponent } from "../../../projects/rfp-list/src/app/proposal-details/grid-date-cell-editor/grid-date-cell-editor.component";
import { GridTimeEditorComponent } from "../components/grid-time-editor/grid-time-editor.component";
import { GridCheckboxEditorComponent } from "../components/grid-checkbox-editor/grid-checkbox-editor.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CreateMessagePageComponent } from "../components/create-message-page/create-message-page.component";
import { DfAlertModule, DfModalService } from "@design-factory/design-factory";
import { NgSelectModule } from "@ng-select/ng-select";
import { GridNumericValidatorComponent } from "../../../projects/rfp-list/src/app/proposal-details/grid-numeric-validator/grid-numeric-validator.component";
import { GridTooltipComponent } from "../../../projects/rfp-list/src/app/proposal-details/grid-tooltip/grid-tooltip.component";
import { NgbActiveModal, NgbAlertModule, NgbDropdownModule, NgbModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertToastComponent } from "../components/alert-toast/alert-toast.component";
import { GridCheckboxRendererComponent } from "../components/grid-checkbox-renderer/grid-checkbox-renderer.component";
import { TranslatePipe } from "../pipes/translate.pipe";
import { ProposalProvidersComponent } from 'projects/rfp-list/src/app/proposal-details/proposal-providers/proposal-providers.component';
import { DownloadAttachmentComponent } from "projects/download-attachment/download-attachment.component";
import { HeaderComponent } from "../components/header/header.component";
import { FilterDropdownActionsPipe } from "../pipes/filter-dropdown-actions.pipe";
import { RfpStatusBadgeDirective } from "../directives/rfp-status-badge.directive";
import { FilterButtonPipe } from "../pipes/filter-button.pipe";
import { TextOverflowTooltipDirective } from "../directives/text-overflow-tooltip.directive";
import { ContentTooltipCellrendererComponent } from "projects/rfp-list/src/app/proposal-details/content-tooltip-cellrenderer/content-tooltip-cellrenderer.component";
import { DfDatePickerModule } from '@design-factory/design-factory';
import { JoinPipe } from "../pipes/join.pipe";

@NgModule({
    declarations: [
        LocalizedCurrencyPipe,
        LocalizedDatePipe,
        LocalizedDateWithoutTimezonePipe,
        SpinnerComponent,
        PageHeaderComponent,
        CopyrightComponent,
        PrivacyTermsComponent,
        PageSubheaderComponent,
        AlertInfoComponent,
        GridTooltipComponent,
        RichTextComponent,
        GridDateCellEditorComponent,
        GridNumericValidatorComponent,
        GridTimeEditorComponent,
        GridCheckboxEditorComponent,
        GridCheckboxRendererComponent,
        CreateMessagePageComponent,
        AlertToastComponent,
        TranslatePipe,
        ProposalProvidersComponent,DownloadAttachmentComponent,
        HeaderComponent,
        RfpStatusBadgeDirective,
        TextOverflowTooltipDirective,
        FilterDropdownActionsPipe,
        FilterButtonPipe,  
        JoinPipe      
    ],
    imports: [
        CommonModule,
        NgbNavModule,
        FormsModule,
        ReactiveFormsModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        DfAlertModule,
        NgSelectModule,
        NgbModule,
        NgbAlertModule,
        NgbTooltipModule,
        NgbDropdownModule,
        DfDatePickerModule
    ],
    providers: [
        DatePipe,
        LocalizedCurrencyPipe,
        LocalizedDatePipe,
        LocalizedDateWithoutTimezonePipe,
        CountryPipe,
        AlertPopupService,
        TranslatePipe,
        JoinPipe,
        NgbActiveModal,
        DfModalService
    ],
    exports: [
        LocalizedCurrencyPipe,
        LocalizedDatePipe,
        LocalizedDateWithoutTimezonePipe,
        SpinnerComponent,
        PageHeaderComponent,
        CopyrightComponent,
        PrivacyTermsComponent,
        PageSubheaderComponent,
        RichTextComponent,
        AlertToastComponent,
        TranslatePipe,
        JoinPipe,
        HeaderComponent,
        FilterDropdownActionsPipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
